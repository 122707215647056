import type { AppProps } from "next/app";
import Script from "next/script";
import "normalize.css/normalize.css";
import "@/styles/global.scss";
import "@/styles/product-configurator.scss";
import { useReducer } from "react";
import { AdobeCategoriesContext } from "state_management/Contexts";
import { AdobeCategoriesReducer } from "state_management/Reducers/AdobeCategories";

export default function App({ Component, pageProps }: AppProps) {
  const buildNumber = process.env.NEXT_PUBLIC_BUILD_NUMBER
    ? process.env.NEXT_PUBLIC_BUILD_NUMBER
    : "";
  const buildSourceVersion = process.env.NEXT_PUBLIC_SOURCE_VERSION
    ? process.env.NEXT_PUBLIC_SOURCE_VERSION
    : "";
  const branchName = process.env.NEXT_PUBLIC_BRANCH_NAME
    ? process.env.NEXT_PUBLIC_BRANCH_NAME
    : "";
  const buildVariables = process.env.NEXT_PUBLIC_BUILD_VARIABLES
    ? process.env.NEXT_PUBLIC_BUILD_VARIABLES
    : "";

  const configuratorVariables = {
    apiUrl: process.env.NEXT_PUBLIC_PRODUCT_API_URL,
    optionConfigurationApiUrl:
      process.env.NEXT_PUBLIC_OPTION_CONFIGURATION_API_URL,
    slaApiUrl: process.env.NEXT_PUBLIC_SLA_API_URL,
  };

  const [state, dispatch] = useReducer(AdobeCategoriesReducer, {
    data: [],
  });

  return (
    <>
      {/* Logs build information added during build process */}
      <Script id="build-info">
        {`
          if (typeof window !== 'undefined') {
            window.cms = {
              buildNumber: '${buildNumber}',
              buildSourceVersion: '${buildSourceVersion}',
              branchName: '${branchName}',
              buildVariables: '${buildVariables}',
              configuratorVariables: ${JSON.stringify(configuratorVariables)}
            };
          }
        `}
      </Script>
      <AdobeCategoriesContext.Provider value={{ state, dispatch }}>
        <Component {...pageProps} />
      </AdobeCategoriesContext.Provider>
    </>
  );
}
