import { createContext } from "react";

type MetaFlagsProps = {
  flags: string[] | null;
  setFlags: React.Dispatch<React.SetStateAction<string[] | null>>;
};

interface AdobeContextType {
  state: any;
  dispatch: (action: { type: string; payload?: any }) => void;
}

export const RikContext = createContext(false);
export const PathContext = createContext({ path: "", search: "" });
export const CustomerEmailAddressContext = createContext("");
export const RewardsContext = createContext("");
export const UserStatusContext = createContext("");
export const SessionIdContext = createContext("");
export const PreviewContext = createContext(false);
export const MetaFlagsContext = createContext<MetaFlagsProps | null>(null);
export const AdobeCategoriesContext = createContext<AdobeContextType | null>(
  null
);
